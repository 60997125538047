/**
 * Created by rudiboutinaud on 20/04/15.
 */

$(document).ready(function(){

    //slick carousel for partners on home page
    $('.autoplay').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    //slick carousel for partners on side menu
    $('.autoplay-small').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3500,
        arrows: false
    });

    $('.confirm').on('click', function(){
        if(confirm('Are you sure?')) {
            return true;
        } else {
            return false;
        }
    })

});